<template>
  <div class="FakeDataImagePlaceholder">
    <img
      v-if="showOnly"
      :src="imageSrc[showOnly]"
      class="is-full-width"
    >
    <template v-else>
      <img
        :src="imageSrc.mobile"
        class="is-hidden-tablet"
      >
      <img
        :src="imageSrc.tablet"
        class="is-hidden-mobile is-hidden-desktop"
      >

      <img
        :src="imageSrc.desktop"
        class="is-hidden-mobile is-hidden-tablet-only"
      >
    </template>
  </div>
</template>

<script>
/**
 * @module FakeDataImagePlaceholder
 */
export default {
  name: 'FakeDataImagePlaceholder',
  props: {
    type: {
      type: String,
      required: true
    },
    showOnly: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageSrc () {
      const imagePath = this.$asset(`/images/components/fake-data-image-placeholder/${this.type}`)
      return {
        mobile: `${imagePath}/mobile.png`,
        // tablet: `${imagePath}/tablet.png`,
        tablet: `${imagePath}/desktop.png`, // TODO: Temporary hack. We dont really support tablet any way
        desktop: `${imagePath}/desktop.png`
      }
    }
  }
}
</script>

<style>
.FakeDataImagePlaceholder img {
  display: block;
  opacity: 0.3;
}
</style>
